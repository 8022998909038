<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Nombre"
            label-for="nombre"
          >
            <b-form-input
              v-model="item.nombre"
              name="nombre"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="4"
        >
          <b-form-group
            label="Código de Renap"
            label-for="codigoRenap"
          >
            <b-form-input
              v-model="item.codigoRenap"
              name="CodigoRenap"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="4"
        >
          <b-form-group
            label="Estado"
            label-for="active"
          >
            <v-select
              v-model="item.active"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="option"
              label="title"
              :disabled="editable"
            >
              <template #option="{ title, icon }">
                <feather-icon
                  :icon="icon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ title }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <br>
        </b-col>
        <b-col cols="12">
          <h4>Municipios</h4>
          <list-table-data
            :table-columns="tableColumns"
            :table-data="municipios"
            redirect-edit=""
            redirect-show="catalogos-municipios-show"
            :origen="catalogoName"
          />
        </b-col>
        <b-col
          v-if="editable"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="editable=false"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span class="align-middle">Editar</span>
          </b-button>
        </b-col>
        <b-col
          v-if="!editable"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateItem"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Actualizar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="cancel"
          >
            <feather-icon
              icon="SlashIcon"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import ListTableData from '@/components/ListTableData.vue'
import { getDepartamento, updateItem } from '@/utils/localizacion'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    ListTableData,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      catalogoName: 'Departamento',
      item: {},
      editable: router.currentRoute.params.action !== 'Edit',
      municipios: [],
      tableColumns: [
        {
          label: 'Nombre',
          field: 'nombre',
        },
        {
          label: 'Código Renap',
          field: 'codigoRenap',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'active',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      option: [
        {
          title: 'ACTIVO',
          icon: 'ToggleRightIcon',
          value: true,
        },
        {
          title: 'INACTIVO',
          icon: 'ToggleLeftIcon',
          value: false,
        },
      ],
    }
  },
  async created() {
    await this.cargarItem()
  },
  methods: {
    async updateItem() {
      const result = await updateItem(this.item, 1)
      if (result !== null) {
        this.$router.replace('/localizacion/departamentos').then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.catalogoName} Actualizado!`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `${this.catalogoName} actualizado correctamente!`,
            },
          })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.catalogoName} no Actualizado`,
            icon: 'FrownIcon',
            variant: 'warning',
            text: `${this.catalogoName} no Actualizado!`,
          },
        })
      }
    },
    async cancel() {
      if (router.currentRoute.params.action === 'Edit') await this.$router.replace('/localizacion/departamentos')
      else await this.cargarItem()
    },
    async cargarItem() {
      try {
        const result = await getDepartamento(router.currentRoute.params.id)
        if (result !== null) {
          this.item = result
          if (this.item.active === undefined) this.item.active = true
          this.municipios = this.item.municipios
          this.item.active = this.item.active ? {
            title: 'ACTIVO',
            icon: 'ToggleRightIcon',
            value: true,
          } : {
            title: 'INACTIVO',
            icon: 'ToggleLeftIcon',
            value: false,
          }
        }
      } catch (err) {
        console.error(`Error en cargar ${this.catalogoName}`, err)
        this.items = null
      }
      this.editable = router.currentRoute.params.action !== 'Edit'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss'
</style>
